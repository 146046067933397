// features/cart/cartSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Initial state
const initialState = {
  cart: [],
  total_items: 0,
  total_amount: 0,
  shipping_fee: 80,
  loading: false,
  error: false,
  msg: "",
};

// Async actions
export const addToCartAction = createAsyncThunk(
  'cart/addToCart',
  async ({ id, amount, product }, { rejectWithValue }) => {
    try {
      return { id, amount, product };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const toggleAmount = createAsyncThunk(
  'cart/toggleAmount',
  async ({ id, value }, { rejectWithValue }) => {
    try {
      return { id, value };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  'cart/deleteProduct',
  async (id, { rejectWithValue }) => {
    try {
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearCart: (state) => {
      state.cart = [];
    },
    clearCartError: (state) => {
      state.error = false;
      state.msg = "";
    },
    countCartTotals: (state) => {
      const { total_items, total_amount } = state.cart.reduce(
        (total, cartItem) => {
          const { amount, price } = cartItem;
          total.total_items += amount;
          total.total_amount += price * amount;
          return total;
        },
        {
          total_items: 0,
          total_amount: 0,
        }
      );
      state.total_items = total_items;
      state.total_amount = total_amount;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToCartAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addToCartAction.fulfilled, (state, action) => {
        const { id, amount, product } = action.payload;
        const tempItem = state.cart.find((i) => i.id === id);
        if (tempItem) {
          const tempCart = state.cart.map((cartItem) => {
            if (cartItem.id === id) {
              let newAmount = cartItem.amount + amount;
              if (newAmount > cartItem.max) {
                newAmount = cartItem.max;
              }
              return { ...cartItem, amount: newAmount };
            } else {
              return cartItem;
            }
          });
          state.cart = tempCart;
          state.msg = "already added !!!";
        } else {
          const newItem = {
            id: id,
            price: product.price,
            product,
            amount,
          };
          state.cart.push(newItem);
          state.msg = "item add successfully";
        }
        state.loading = false;
      })
      .addCase(addToCartAction.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.msg = action.payload;
      })
      .addCase(toggleAmount.pending, (state) => {
        state.loading = true;
      })
      .addCase(toggleAmount.fulfilled, (state, action) => {
        const { id, value } = action.payload;
        const tempCart = state.cart.map((item) => {
          if (item.id === id) {
            if (value === "inc") {
              let newAmount = item.amount + 1;
              if (newAmount > item.max) {
                newAmount = item.max;
              }
              return { ...item, amount: newAmount };
            }
            if (value === "dec") {
              let newAmount = item.amount - 1;
              if (newAmount < 1) {
                newAmount = 1;
              }
              return { ...item, amount: newAmount };
            }
          }
          return item;
        });
        state.cart = tempCart;
        state.loading = false;
      })
      .addCase(toggleAmount.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        const tempCart = state.cart.filter((item) => item.id !== action.payload);
        state.cart = tempCart;
        state.loading = false;
      })
      .addCase(deleteProduct.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  }
});

export const { clearCart, clearCartError, countCartTotals } = cartSlice.actions;

export default cartSlice.reducer;
