import Link from "next/link";

import User from "../Offcanvas/User";
import { useAppContext } from "@/context/Context";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

function capitalizeFirstLetter(string) {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const HeaderRightTwo = ({ btnClass, btnText, userType }) => {
  const { mobile, setMobile, search, setSearch, cartToggle, setCart } =
    useAppContext();

  const { data: session } = useSession();

  const router = useRouter();

  return (
    <div
      className="header-right"
      style={{
        height: "80px",
      }}
    >
      <ul className="quick-access">
        {/* <li className="access-icon">
          <Link
            className={`search-trigger-active rbt-round-btn ${
              search ? "" : "open"
            }`}
            href="#"
            onClick={() => setSearch(!search)}
          >
            <i className="feather-search"></i>
          </Link>
        </li> */}
        {/*     
        <li className="access-icon rbt-mini-cart">
          <Link
            className="rbt-cart-sidenav-activation rbt-round-btn"
            href="#"
            onClick={() => setCart(!cartToggle)}
          >
            <i className="feather-shopping-cart"></i>
            <span className="rbt-cart-count">{total_items}</span>
          </Link>
        </li> */}
        {process.env.NODE_ENV === "development" && (
          <>
            <li className="access-icon rbt-user-wrapper d-block d-xl-none">
              <Link className="rbt-round-btn" href="#">
                <i className="feather-user"></i>
              </Link>
              <User />
            </li>
            {session ? (
              <li className="account-access rbt-user-wrapper d-none d-xl-block">
                <Link href="#">
                  <i className="feather-user"></i>
                  {capitalizeFirstLetter(session.user.role)}
                </Link>
                <User user={session.user} />
              </li>
            ) : (
              <li className=" rbt-user-wrapper d-none d-xl-block me-4">
                <Link href="/login" className="d-flex align-items-center me-4">
                  <i className="feather-log-in"></i>
                  Log in
                </Link>
              </li>
            )}
          </>
        )}
      </ul>

      <div className="rbt-btn-wrapper d-none d-xl-flex">
        <Link
          className={`rbt-btn ${btnClass} d-flex align-items-center`}
          href="/contact"
        >
          <span
            data-text={`${btnText}`}
            className={router.pathname === "/[slug]" ? "animation-none" : ""}
          >
            {btnText}
          </span>
        </Link>
      </div>

      <div className="mobile-menu-bar d-block d-xl-none">
        <div className="hamberger">
          <button
            className="hamberger-button rbt-round-btn"
            onClick={() => setMobile(!mobile)}
          >
            <i className="feather-menu"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeaderRightTwo;
